/* DarkTypeahead.css */

/* The multi-input area (where tokens appear) */
.dark-typeahead .rbt-input-multi.form-control {
  background-color: #1c1d21 !important;
  color: #dddddd !important;
  border: 1px solid #383a40 !important;
}

/* The tokens themselves */
.dark-typeahead .rbt-token {
  background-color: #003df5 !important; /* or #272727 if you want darker tokens */
  color: #dddddd !important;
  border: none !important;
  margin: 2px !important; /* optional, for spacing */
}

/* The 'x' remove icon on tokens */
.dark-typeahead .rbt-token-remove {
  color: #dddddd !important;
  opacity: 0.8;
}
.dark-typeahead .rbt-token-remove:hover {
  opacity: 1;
}

/* The dropdown menu */
.dark-typeahead .rbt-menu.dropdown-menu {
  background-color: #1c1d21 !important;
  color: #dddddd !important;
  border: 1px solid #383a40 !important;
}

/* Hover/active states in the dropdown */
.dark-typeahead .rbt-menu .active,
.dark-typeahead .dropdown-item.active {
  background-color: #272727 !important;
  color: #dddddd !important;
}

/* The "Add a new skill: " text for custom selections */
.dark-typeahead .rbt-menu-new-option {
  color: #aaaaaa !important;
}
