@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 98%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 98%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    --accent-primary-default: 0, 89, 236;
    --accent-primary-dimmer: 29, 37, 150;
    --accent-primary-higher: 233, 244, 255;
    --accent-positive-dimmer: 38, 145, 65;
    --accent-positive-default: 40, 169, 72;
    --accent-positive-higher: 230, 252, 230;
    --accent-negative-dimmer: 234, 0, 29;
    --accent-negative-default: 252, 3, 54;
    --accent-negative-higher: 255, 232, 234;
    --accent-warning-dimmer: 255, 147, 1;
    --accent-warning-default: 255, 173, 2;
    --accent-warning-higher: 255, 244, 207;
    --foreground-default: 23, 23, 23;
    --foreground-dimmer: 102, 102, 102;
    --foreground-dimmest: 151, 151, 151;
    --foreground-dark-default: 255, 255, 255;
    --foreground-dark-dimmer: 220, 220, 220;
    --foreground-dark-dimmest: 180, 180, 180;
    --background-dark-root: 18, 20, 22;
    --background-dark-default: 28, 29, 33;
    --background-dark-dimmer: 46, 47, 52;
    --background-dark-dimmest: 56, 58, 64;
    --background-dark-deepest: 90, 92, 98;
    --background-root: 249, 249, 249;
    --background-default: 255, 255, 255;
    --background-dimmer: 242, 242, 242;
    --background-dimmest: 233, 233, 233;
    --background-deepest: 201, 201, 201;
    --landing-background: 21, 24, 36;
  }
}

@layer utilities {
  .animation-paused {
    animation-play-state: paused;
  }
}

.lds-ring-smaller {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}

.lds-ring-smaller div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 33px;
  height: 33px;
  margin: 3px;
  border: 3px solid #666;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #666 transparent transparent transparent;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 44px;
  height: 44px;
  margin: 4px;
  border: 4px solid #666;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #666 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
