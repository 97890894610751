$primary: #1e40af;
$light: #ebecf2;
$success: #1a9726;

$white: #fff;

.btn-success {
  color: $white !important;
}

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
