/* CustomModal.css */
.dark-modal-dialog {
  max-width: 800px;
}

.dark-modal-content {
  background-color: #121416;
  border: 1px solid #333;
  border-radius: 0.5rem;
  color: #eeeeee;
}

.dark-modal-header {
  border-bottom: 1px solid #333;
  background-color: #121416;
  color: #eeeeee;
  padding: 1rem 1.5rem;
}

.dark-modal-header .btn-close {
  color: #eeeeee;
  filter: invert(1) brightness(1.8);
  opacity: 0.8;
}

.dark-modal-header .btn-close:hover {
  opacity: 1;
}

.dark-modal-body {
  background-color: #121416;
  padding: 1.5rem;
}

.dark-modal-footer {
  border-top: 1px solid #333;
  background-color: #121416;
  padding: 1rem 1.5rem;
}

/* Override Bootstrap form control styles for dark theme */
.dark-modal-body .form-control {
  background-color: #1c1d21;
  border: 1px solid #333;
  color: #dddddd;
}

.dark-modal-body .form-control:focus {
  background-color: #1c1d21;
  border-color: #1240d0;
  box-shadow: 0 0 0 0.25rem rgba(18, 64, 208, 0.25);
  color: #dddddd;
}

.dark-modal-body .form-label {
  color: #eeeeee;
}

/* Custom button styling */
.dark-modal-footer .btn-primary {
  background-color: #1240d0;
  border-color: #1240d0;
  color: #dddddd;
}

.dark-modal-footer .btn-primary:hover {
  background-color: #0d2f8e;
  border-color: #0d2f8e;
}

/* Fix placeholder text color */
.dark-modal-body .form-control::placeholder {
  color: #666666;
}

/* Add this to your CustomModal.css file */

/* Dark theme for popovers */
.dark-popover .popover-body {
  background-color: #2e2f34 !important;
  border: 1px solid #333 !important;
  color: #dddddd !important;
}

.dark-popover .popover-arrow::before,
.dark-popover .popover-arrow::after {
  border-bottom-color: #333 !important;
}

.dark-popover .popover-arrow::after {
  border-bottom-color: #1c1d21 !important;
}

/* Style for dropdown items in dark theme */
.dark-popover .dropdown-item {
  color: #dddddd !important;
}

.dark-popover .dropdown-item:hover,
.dark-popover .dropdown-item:focus {
  background-color: #121416 !important;
  color: #eeeeee !important;
}

/* Style for form controls in dark theme */
.dark-popover .form-check-input {
  background-color: #121416 !important;
  border-color: #333 !important;
}

.dark-popover .form-check-input:checked {
  background-color: #1240d0 !important;
  border-color: #1240d0 !important;
}
