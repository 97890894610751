body {
  background-color: #eee;
}

.card {
  border: none;
  border-radius: 10px;
}

.progress {
  height: 10px;
  border-radius: 10px;
}

.progress div {
  background-color: red;
}

.text1 {
  font-size: 14px;
  font-weight: 600;
}

.text2 {
  color: #a5aec0;
}

.bubble {
  border: 2px solid;
  box-shadow: 0px 0px 0px 0px inset;
  height: 2.5rem;
  width: 2.5rem;
}

.bubble.filled {
  background-color: #00ea90;
  border: 2px solid #00ea90;
  box-shadow: 0px 0px 0px 0px #00ea90 inset;
}

.bubble.unfilled {
  border: 2px solid #d6d3d1;
  box-shadow: 0px 0px 0px 0px #d6d3d1 inset;
}
