.dark-date-input {
  background-color: rgba(28, 29, 33, 1) !important; /* #1C1D21 */
  color: #dddddd !important;
  border: 1px solid rgba(56, 58, 64, 1) !important; /* #383A40 */
  border-radius: 4px;
  outline: none !important;
}

.dark-date-input:focus {
  background-color: rgba(28, 29, 33, 1) !important;
  color: #dddddd !important;
  border-color: rgba(56, 58, 64, 1) !important;
  box-shadow: 0 0 0 1px rgba(56, 58, 64, 1) !important;
  outline: none !important;
}
